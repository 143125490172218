<template>
  <div id="busking">
    <app-navbar></app-navbar>
    <app-slider-component
        :slides="sliderPhoto"
        :time="3000"
    >
    </app-slider-component>
    <div class="yellowWrap">
      <img v-parallax="0.4" class="img-fluid" src="@/assets/yellowWrap.png" alt="yellowWrap">
    </div>
    <div class="cropWhiteBalls">
      <img v-parallax="0.1" class="img-fluid" src="@/assets/cropWhiteBalls.png" alt="cropWhiteBalls">
    </div>
    <!--    <div class="color-yellow-box"></div>-->
    <div class="container">

      <!--      <div class="col-lg-10 offset-lg-1">-->
      <!--        <p class="pt-lg-4">{{ "Busking.first" | staticTranslation }}</p>-->
      <!--      </div>-->

      <!--      <div class="pt-2 pb-4">-->
      <!--        <img class="img-fluid" src="@/assets/busking/buskinig_2022.jpeg" alt="Busking_2022">-->
      <!--      </div>-->

      <div class="col-lg-10 offset-lg-1 pt-4">

        <!--        <p class="pt-4 pb-4">{{ "Busking.end2022" | staticTranslation }}</p>-->
        <!--        <p class="pt-lg-1">{{ "Busking.second" | staticTranslation }}</p>-->
        <!--        <p class="pt-lg-1"><b>Mariusz Golib</b> {{ "Busking.MariuszGolib" | staticTranslation }}</p>-->
        <!--        <p class="pt-lg-1"><b>Jannis Moras</b> {{ "Busking.JannisMoras" | staticTranslation }}</p>-->
        <!--        <p class="pt-lg-1"><b>LEV RADAGAN</b> {{ "Busking.LEVRADAGAN" | staticTranslation }}</p>-->
        <!--        <p class="pt-lg-1"><b>Ray Romijn</b> {{ "Busking.RayRomijn" | staticTranslation }}</p>-->
        <!--        <p class="pt-lg-1"><b>ILHAAM Project</b> {{ "Busking.ILHAAMProject" | staticTranslation }}</p>-->
        <!--        <p class="pt-lg-1"><b>PsicoNauti</b> {{ "Busking.PsicoNauti" | staticTranslation }}</p>-->
        <!--        <p class="pt-lg-1"><b>Flaškinet</b> {{ "Busking.Flaškinet" | staticTranslation }}</p>-->
        <!--        <p class="pt-lg-1"><b>Metošé</b> {{ "Busking.Metošé" | staticTranslation }}</p>-->
        <!--        <p class="pt-lg-1"><b>Radosti</b> {{ "Busking.Radosti" | staticTranslation }}</p>-->
        <!--        <p class="pt-lg-1"><b>Cigarette Pillowbag</b> {{ "Busking.CigarettePillowbag" | staticTranslation }}</p>-->
      </div>

      <h2 class="text-center">{{ "Busking.label" | staticTranslation }}</h2>
      <div class="col-lg-10 offset-lg-1">
        <p class="pt-lg-4">{{ "Busking.articleFirst" | staticTranslation }}</p>
<!--        <p>{{ "Busking.articleSecond" | staticTranslation }}</p>-->
<!--        <p>{{ "Busking.articleThird" | staticTranslation }}</p>-->

<!--        <div>-->
<!--        <h4>Peter Juhás</h4>-->
<!--          <p>{{ "Busking.juhas" | staticTranslation }}</p>-->

<!--          <h4>Martin Harich</h4>-->
<!--          <p>{{ "Busking.harich" | staticTranslation }}</p>-->

<!--          <h4>Bára</h4>-->
<!--          <p>{{ "Busking.bara" | staticTranslation }}</p>-->


<!--          <h4>Mário Bihári</h4>-->
<!--          <p>{{ "Busking.bihari" | staticTranslation }}</p>-->


<!--          <h4>Sam Rychtár</h4>-->
<!--          <p>{{ "Busking.rychtar" | staticTranslation }}</p>-->


<!--          <h4>Sarah MacDougall-->
<!--          </h4>-->
<!--          <p>{{ "Busking.macDougall" | staticTranslation }}</p>-->


<!--          <h4>-->
<!--            Šedovous-->
<!--          </h4>-->
<!--          <p>{{ "Busking.sedovous" | staticTranslation }}</p>-->


<!--          <h4>Flaškinet</h4>-->
<!--          <p>{{ "Busking.flaskinet" | staticTranslation }}</p>-->


<!--          <h4>TBEE Girls-->
<!--          </h4>-->
<!--          <p>{{ "Busking.girls" | staticTranslation }}</p>-->


<!--          <h4>Mucha</h4>-->
<!--          <p>{{ "Busking.mucha" | staticTranslation }}</p>-->


<!--          <h4>Boris</h4>-->
<!--          <p>{{ "Busking.boris" | staticTranslation }}</p>-->


<!--          <h4>Urband</h4>-->
<!--          <p>{{ "Busking.urband" | staticTranslation }}</p>-->


<!--          <h4>Jiří Schmitzer-->
<!--          </h4>-->
<!--          <p>{{ "Busking.schmitzer" | staticTranslation }}</p>-->

<!--        </div>-->

        <!--            <h2 class="text-center pt-lg-4">{{ "Busking.prepareBuskingProgramOne" | staticTranslation }}</h2>-->
        <!--            <h3 class="text-center pt-lg-4 last-year">{{ "Busking.prepareBuskingProgramTwo" | staticTranslation }}</h3>-->
        <!--            <h2 class="text-center pt-lg-4">HOMO BUSKER</h2>-->
        <!--              <p>{{ "Busking.articleFour" | staticTranslation }}</p>-->
        <!--              <p>{{ "Busking.text" | staticTranslation }}</p>-->
        <!--              <h3 class="text-center pt-lg-2">{{ "Busking.lookForwardTo" | staticTranslation }}</h3>-->
        <!--              <div class="text-center">-->
        <!--                <p>Crazy Accordeon Trio</p>-->
        <!--                <p>Mariusz Goli</p>-->
        <!--                <p>Stephen Paul Taylor</p>-->
        <!--                <p>Hazafele</p>-->
        <!--                <p>Metošé</p>-->
        <!--                <p>Dooušek</p>-->
        <!--                <p>Illegal String Quartet</p>-->
        <!--                <p>Malej Péťa & Shizzle</p>-->
        <!--                <p>TBee Girls</p>-->
        <!--                <p>Martin Binder</p>-->
        <!--                <p>Pepa Klíč</p>-->
        <!--                <p>Samuel Rychtar</p>-->
        <!--                <p>Docela kapela</p>-->
        <!--                <p>Jakub Cinibulk</p>-->
        <!--                <p>Tereza Marečková</p>-->
        <!--                <p>ZUŠ Open</p>-->
        <!--                <p>Eva Králíčková</p>-->
        <!--                <p>Barbora Škrabálková</p>-->
        <!--                <p>David Polhoš</p>-->
        <!--              </div>-->
        <!--              <div class="text-center">-->
        <!--                <img class="img-fluid pt-lg-4" width="70%" src="@/assets/busking/busking.jpg" alt="busking">-->
        <!--              </div>-->

        <!--              <h4 class="participants-label">Rozpis buskerů – NEDĚLE</h4>-->
        <!--              <div class="row">-->
        <!--                <div class="col-lg-6"><img class="img-fluid" src="@/assets/buskingPhoto1.jpeg" alt="buskingPhoto1"></div>-->
        <!--                <div class="col-lg-6 pt-4 pt-lg-0"><img class="img-fluid" src="@/assets/buskingPhoto2.jpeg" alt="buskingPhoto2"></div>-->
        <!--              </div>-->
        <!--              <h3 class="text-center list-buskers">Seznam buskerů</h3>-->
        <!--              <table class="table">-->
        <!--                <tbody class="text-center">-->
        <!--                <tr>-->
        <!--                  <td>Stephen Paul Taylor</td>-->
        <!--                  <td>Metošé</td>-->
        <!--                </tr>-->
        <!--                <tr>-->
        <!--                  <td>Crazy Accordion Trio</td>-->
        <!--                  <td>Jakub Cinibulk</td>-->
        <!--                </tr>-->
        <!--                <tr>-->
        <!--                  <td>Květy</td>-->
        <!--                  <td>Dooušek</td>-->
        <!--                </tr>-->
        <!--                <tr>-->
        <!--                  <td>Nikola Mucha</td>-->
        <!--                  <td>Jitka Malczyk a Vojtěch Jindra</td>-->
        <!--                </tr>-->
        <!--                <tr>-->
        <!--                  <td>DebbiLove</td>-->
        <!--                  <td>Mariachi Espuelas</td>-->
        <!--                </tr>-->
        <!--                <tr>-->
        <!--                  <td>Fanda Tříska</td>-->
        <!--                  <td>Hazafele</td>-->
        <!--                </tr>-->
        <!--                <tr>-->
        <!--                  <td>Illegal String Quartet</td>-->
        <!--                  <td></td>-->
        <!--                </tr>-->
        <!--                </tbody>-->
        <!--              </table>-->
        <!--              <table class="table">-->
        <!--                <tbody class="text-center">-->
        <!--                <tr>-->
        <!--                  <td>Paperboat</td>-->
        <!--                  <td class="bold">Dětský pokoj:</td>-->
        <!--                </tr>-->
        <!--                <tr>-->
        <!--                  <td>Petr Špatina</td>-->
        <!--                  <td>Samuel Rychtár</td>-->
        <!--                </tr>-->
        <!--                <tr>-->
        <!--                  <td>Květy</td>-->
        <!--                  <td>Dooušek</td>-->
        <!--                </tr>-->
        <!--                <tr>-->
        <!--                  <td>Nikola Mucha</td>-->
        <!--                  <td>Jitka Malczyk a Vojtěch Jindra</td>-->
        <!--                </tr>-->
        <!--                <tr>-->
        <!--                  <td>DebbiLove</td>-->
        <!--                  <td>Mariachi Espuelas</td>-->
        <!--                </tr>-->
        <!--                <tr>-->
        <!--                  <td>Fanda Tříska</td>-->
        <!--                  <td>Hazafele</td>-->
        <!--                </tr>-->
        <!--                <tr>-->
        <!--                  <td>Illegal String Quartet</td>-->
        <!--                  <td></td>-->
        <!--                </tr>-->
        <!--                </tbody>-->
        <!--              </table>-->
      </div>
    </div>
    <div class="col-lg-1 offset-lg-1 yellowBallone">
      <img v-parallax="0.2" class="img-fluid" src="@/assets/yellowBallone.png" alt="yellowBallone">
    </div>

    <app-footer></app-footer>
  </div>
</template>

<script>
import navbar from "@/components/base/navbar";
import footer from "@/components/base/footer";
import SliderComponent from "@/components/base/pageHome/SliderComponent";

export default {
  name: "Interprets",
  components: {
    'app-navbar': navbar,
    'app-footer': footer,
    'app-slider-component': SliderComponent
  },
  computed: {
    isActiveCs: function () {
      return !(localStorage.getItem('lang') === 'en')
    },
    isActiveEn: function () {
      return (localStorage.getItem('lang') === 'en')
    }
  },
  data() {
    return {
      sliderPhoto: ["/sliderBusking/1.jpg", "/sliderBusking/2.jpeg", "/sliderBusking/3.jpg", "/sliderBusking/4.jpeg", "/sliderBusking/5.jpg"]
    }
  }
}
</script>

<style scoped>
h1 {
  font-size: 3.5em;
  padding-top: 3em;
  padding-bottom: 50px;
  font-family: "Transcript Pro";
}

h2 {
  font-size: 2.5em;
  font-family: "Transcript Pro";
}

h3 {
  font-family: "Transcript Pro";
}

.last-year {
  font-family: "Transcript Mono";
}

h4 {
  font-family: "Transcript Pro";
}

p {
  font-family: "Transcript Mono";
}

b {
  font-family: "Transcript Pro";
}

.color-yellow-box {
  position: absolute;
  top: 110vh;
  width: 91%;
  height: 129vh;
  background-color: #FFCE6D;
}

.table, td {
  font-family: "Transcript Mono";
  border: none;
}

#busking {
  background-color: #ED5B2D;
}

.list-buskers {
  padding-top: 3em;
}

.participants-label {
  display: inline-block;
  padding: 8px 6px 4px 6px;
  background-color: #FFCE6D;
  color: black;
  margin-bottom: 3em;
}

.yellowBallone {
  padding-bottom: 3em;
  width: 7%;
  left: 2%;
}

.yellowWrap {
  position: absolute;
  text-align: end;
  right: 3%;
  width: 14%;
  top: 60vh;
}

.cropWhiteBalls {
  position: absolute;
  width: 6%;
  top: 25vh;
}
</style>